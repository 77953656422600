body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas,
        'Courier New', monospace;
}

.navbar {
    margin-bottom: 20px;
}

.card {
    height: 600px;
    margin-bottom: 30px;
}

.card-img-container {
    position: relative;
    display: inline-block;
}

.card-img-container img {
    max-height: 250px;
}

.card-img-container .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.card-img-container .overlay .badge {
    color: white;
    margin-left: 10px;
    margin-top: 10px;
}

.card-body a {
    margin: 0 5px;
}

.site-pablosyogashop {
    background-image: url('images/pablosyogashop3.png');

    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.site-121auto {
    background-image: url('images/121auto.png');
}

.sites {
    height: 250px;
    position: relative;
}

.sites a {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
}

.sites a:hover {
    color: white;
}

.sites a.black {
    color: black;
}

.sites a.black:hover {
    color: black;
}
